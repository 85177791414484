import Article from './article/Article';
import Archive from './pages/arkiv/ArchiveTemplate';
import ArealApplication from './pages/arealansøgning/ArealApplication';
import BeredskabPage from './pages/beredskab/beredskabPage';
import BrugerPanel from './layout/footer/Brugerpanel';
import ContactPage from './layout/footer/ContactPage';
import DataBundlePage from './datacatalog/DataBundle/DataBundlePage';
import DataCatalogPage from './datacatalog/DataCatalogPage';
import ForsidePage from "./pages/forside/ForsidePage";
import Giskort from './giskort/Giskort';
import Header from "./layout/header/Header";
import InteresseTemplate from './pages/interesse/interesseTemplate';
import KlimadataPage from './pages/klimadata/klimadataPage';
import KortPage from './pages/kort/KortPage';
import LaeringPage from './pages/laering/laeringPage';
import NotFound404 from './NotFound404';
import PlandataPage from './pages/plandata/PlandataPage';
import SektorplanerPage from './pages/sektorplaner/SektorplanerPage';
import SmoothScroll from './layout/SmoothScroll';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Suspense } from 'react';
import { useFetchDataBundleItems } from './api/WpDataBundle';
import './App.css';

function App() {
  const { dataBundleItems } = useFetchDataBundleItems();
  return (
    <div>
      <Suspense>
        {dataBundleItems &&
        <BrowserRouter>
          <div className="App">
            <div className="content">
              <SmoothScroll>
              <Routes>
                <Route element={<Header/>}>
                  <Route index element={<ForsidePage items={dataBundleItems} />}></Route>
                  <Route path="/" element={<ForsidePage items={dataBundleItems} />}></Route>
                  <Route path='/plandata' element={<PlandataPage items={dataBundleItems}/>}></Route>
                  <Route path='/data-katalog' element={<DataCatalogPage items={dataBundleItems} />}></Route>
                  <Route path='/data-katalog/:id' element={<DataBundlePage items={dataBundleItems} />}></Route>
                  <Route path='/plandata/giskort' element={<Giskort appID="https://kort.nunagis.gl/portal/apps/webappviewer/index.html?id=f737cced1f8b452a8af62e437ce0c9bc"/>}></Route>
                  <Route path='/kort' element={<KortPage items={dataBundleItems}/>}></Route>
                  <Route path='/kort/giskort' element={<Giskort appID="https://kort.nunagis.gl/portal/apps/webappviewer/index.html?id=064e8f91f1f34d5695e20f0e5bc9000c"/>}></Route>
                  <Route path='/sektorplaner'  element={<SektorplanerPage items={dataBundleItems}/>}></Route>
                  <Route path='/sektorplaner/giskort' element={<Giskort appID="https://kort.nunagis.gl/portal/apps/webappviewer/index.html?id=39bea794367345698bfabb1aab6a4685"/>}></Route>
                  <Route path='/klimadata'  element={<KlimadataPage items={dataBundleItems}/>}></Route>
                  <Route path='/klimadata/giskort'  element={<Giskort appID=" https://kort.nunagis.gl/klimadata/"/>}></Route>
                  <Route path='/beredskab'  element={<BeredskabPage items={dataBundleItems}/>}></Route> 
                  <Route path='/beredskab/giskort' element={<Giskort appID="https://kort.nunagis.gl/portal/apps/webappviewer/index.html?id=6c454e35e4dd4727be2c6aa22eff894a"/>}></Route>
                  <Route path='/læring'  element={<LaeringPage items={dataBundleItems}/>}></Route>
                  <Route path='/læring/giskort' element={<Giskort appID="https://eu.opencitiesplanner.bentley.com/niras/greenland"/>}></Route>
                  <Route path='/interesser/:interest'  element={<InteresseTemplate items={dataBundleItems}/>}></Route>
                  <Route path='/arkiv'  element={<Archive/>}></Route>
                  <Route path='/arkiv/:interest'  element={<Archive/>}></Route>
                  <Route path='/articles'  element={<Article />}></Route>
                  <Route path='/article/:articleId'  element={<Article/>}></Route>
                  <Route path='/arealansøgning'  element={<ArealApplication/>}></Route>
                  <Route path='/brugerpanel'  element={<BrugerPanel/>}></Route>
                  <Route path='/kontakt-os'  element={<ContactPage/>}></Route>
                  <Route path='*' element={<NotFound404 />}></Route>  
                </Route>
              </Routes>
              </SmoothScroll>
            </div>
          </div >
        </BrowserRouter>        
        }
      </Suspense>
    </div>
  );
}


export default App;
