import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface HeroactionInput {
  navDestination: string;
}

const Heroaction = (props: HeroactionInput) => {
  const { navDestination } = props;
  const { t } = useTranslation("translation", { keyPrefix: "heroaction" });
  const iconURL = "/assets/icons/Pil.svg";

  const isAbsoluteURL = (link: string) => {
    return /^(http|https):\/\//.test(link);
  };

  if (isAbsoluteURL(navDestination)) {
    const tooltip = t("goto-tooltip-absolute");
    return (
      <a href={navDestination} target="_blank" title={tooltip} rel="noreferrer" className="flex flex-col items-center gap-3 cursor-pointer ml-4">
        <img src={iconURL} 
          style={{ height: "56px"}}
          alt={"Go to app"} />
        <label className="text-xs">{t("goto-label-absolute")}</label>
      </a>
    );
  } else {
    const tooltip = t("goto-tooltip");

    
    return (
      <Link to={navDestination} title={tooltip} className="flex flex-col items-center gap-3 cursor-pointer ml-4">
        <img src={iconURL}
          style={{ height: "56px"}} 
          alt={"Go to app"} />
        <label className="text-xs">{t("goto-label")}</label>
      </Link>
    );
  }
};

export default Heroaction;
