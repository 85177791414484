import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

interface MainMenuInput {
  isMobile: boolean;
}

const MainMenu = ({isMobile}: MainMenuInput) => {
  const { t } = useTranslation("translation", { keyPrefix: "header" });

  let activeStylePage = {
    color: "#3ecddd",
    background: "black",
  };

  const mainNavRoutes = [
    { url: "/", label: t("home") },
    { url: "/kort", label: t("map") },
    { url: "/plandata", label: t("plandata") },
    { url: "/sektorplaner", label: t("sectorplans") },
    { url: "/klimadata", label: t("climatedata") },
    { url: "/beredskab", label: t("emergency") },
    { url: "/læring", label: t("learning") },
  ];

  return (
    <nav className={`flex ${isMobile?"flex-col pr-2 pl-2":"hide-in-mobile gap-px"} main-menu text-center bg-black text-gray-300 uppercase font-semibold justify-between lg:text-base items-stretch`}>
      {mainNavRoutes.map((route, i) => {
        const breakClass = route.label.includes(" ") ? "break-word" : "break-all";
        if (route.url === "/") {
          return (
            <NavLink
              key={i}
              to={route.url}
              title={route.label}
              className={` ${isMobile?"min-h-[45px]":""} p-1.5 flex-1 items-center bg-[#292929] font-light hover:bg-black hover:text-[#3ecddd]`}
              style={({ isActive }) => isActive ? activeStylePage : undefined }
            >
              {({ isActive }) => (
                <span
                  className={`flex ${isMobile?"":"justify-center"} hover:text-[#3ecddd] font-normal`}
                  style={ isActive ? activeStylePage : undefined }
                >
                  <img
                    src={"/assets/icons/icon_home_grey.svg"}
                    alt="home"
                    className="mr-2"
                  />
                  {route.label}
                </span>
              )}
            </NavLink>
          );
        } else {
          return (
            <NavLink
              key={i}
              to={route.url}
              className={`p-1.5 flex-1 ${isMobile?"text-start min-h-[45px]":""} bg-[#292929] font-normal hover:bg-black hover:text-[#3ecddd] ${breakClass}`}
              title={route.label}
              style={({ isActive }) =>
                isActive ? activeStylePage : undefined
              }
            >
              {route.label}
            </NavLink>
          );
        }
      })}
    </nav>
  )
};

export default MainMenu;
