import { Suspense, useEffect, useState } from "react";
import { Outlet, NavLink, useLocation } from "react-router-dom";
import Interessenav from "./Interessenav";
import { useFetchArticleItems } from "../../api/WpArticle";
import {
  wpArticleToArticleItem,
  wpLinkcardtoLinkcardItem,
} from "../../api/ItemMapper";
import { ArticleItem } from "../../article/Article";
import Footer from "../footer/Footer";
import { useFetchlinkcardItems } from "../../api/WpLinkcards";
import { LinkCardItem } from "../../pages/forside/ForsidePage";
import MainMenu from "./MainMenu";
import DataMenu from "./DataMenu";
import OthersMenu from "./OthersMenu";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { articles } = useFetchArticleItems();
  const { linkcard } = useFetchlinkcardItems();

  const [gisKort, setGisKort] = useState(Boolean);
  const [isShowBurgerMenu, setShowBurgerMenu] = useState<Boolean>(false);

  const { i18n } = useTranslation("translation", { keyPrefix: "header" });

  let articleItems: ArticleItem[] = [];
  articles.forEach((wpArticle) => {
    articleItems.push(wpArticleToArticleItem(wpArticle));
  });

  let linkCardItems: LinkCardItem[] = [];
  linkcard.forEach((wpLinkcard) => {
    linkCardItems.push(wpLinkcardtoLinkcardItem(wpLinkcard));
  });

  const allArticleItems = [...linkCardItems, ...articleItems];

  let activeStyleIcon = {
    background: "#3ecddd",
  };

  const currentLocation = useLocation();
  const locationArray = currentLocation.pathname
    .replaceAll("/", " ")
    .trim()
    .split(" ");

  useEffect(() => {
    if (locationArray.includes("giskort")) {
      setGisKort(true);
    } else {
      setGisKort(false);
    }
  }, [locationArray]);

  const changeLanguage = () => {
    const lang = i18n.language === "da" ? "gl" : "da";
    i18n.changeLanguage(lang);
  };

  const getMobileBurgerMenu = (): JSX.Element => {
    return <div className="flex flex-col gap-4">
      <MainMenu isMobile={true}></MainMenu>
      <div className="border-t border-[#292929] mx-0"></div>
      <DataMenu isMobile={true}></DataMenu>
      <div className="border-t border-[#292929] mx-0"></div>
      <Interessenav isMobile={true}/>
      <div className="border-t border-[#292929] mx-0"></div>
      <OthersMenu isMobile={true} changeLanguage={() => { changeLanguage()}} />
    </div>
  }

  
  const getMobileMenu = (): JSX.Element => {
    return <section className="show-in-mobile max-w-8xl mx-auto bg-slate-600 border-b border-[#292929]">
      <MainMenu isMobile={false}/>
      <div className="flex items-center justify-between text-gray-300 font-light bg-black h-24">
        <NavLink to="/">
          <div className="flex mx-5 lg:mx-10 gap-2 lg:gap-5">
            <Suspense>
              <img
                src={"/assets/icons/nunaGIS_Logo_Big.svg"}
                className="hide-in-tiny"
                alt="Nunagis logo"
              />
              <img
                src={"/assets/icons/nunaGIS_Logo_Small_notext.svg"}
                className="show-in-tiny"
                alt="Nunagis"
              />
            </Suspense>
          </div>
        </NavLink>
        <img 
          className="cursor-pointer m-2 w-10" 
          src="/assets/icons/icon_burger.svg"
          alt="Menu"
          onClick={() => { setShowBurgerMenu(!isShowBurgerMenu) }}
        />
      </div>
    </section>
  }

  const getDesktopMenu = (): JSX.Element => {
    return <section className="hide-in-mobile max-w-8xl mx-auto bg-slate-600 border-b border-[#292929]">
      <MainMenu isMobile={false}/>
      <div className="flex items-center justify-between text-gray-300 font-light bg-black h-24">
        <NavLink to="/">
          <div className="flex mx-5 lg:mx-10 gap-2 lg:gap-5">
            <Suspense>
              <img
                src={"/assets/icons/nunaGIS_Logo_Big.svg"}
                alt="Nunagis logo"
              />
            </Suspense>
          </div>
        </NavLink>
        <DataMenu isMobile={false}/>
        <div className="h-12 border-l border-[#292929] mx-0"></div>
        <Interessenav isMobile={false} />
        <div className="h-12 border-l border-[#292929]"></div>
        <OthersMenu isMobile={false} changeLanguage={() => { changeLanguage()}}/>
      </div>
    </section>
  }

  const getMapMenu = (): JSX.Element => {
    return <section className="max-w-8xl m-4 bg-slate-600">
      <nav className="gap-px bg-black text-gray-300 uppercase font-semibold flex justify-between items-center">
        <div className="flex  justify-between text-gray-300 font-light bg-black">
          <div>
            <NavLink to="/">
              <div className="flex mx-5">
                <Suspense>
                  <img
                    src={"/assets/icons/nunaGIS_Logo_Big.svg"}
                    className="hidden sm:block"
                    alt="Nunagis logo"
                  />
                  <img
                    src={"/assets/icons/nunaGIS_Logo_Small_notext.svg"}
                    className="block sm:hidden"
                    alt="Nunagis logo"
                  />
                </Suspense>
              </div>
            </NavLink>
          </div>
          <NavLink
            to={`/${locationArray[0]}`}
            className="text-[12px] font-black self-center hover:text-[#3ECDDD] cursor-pointer"
          >
            {`${locationArray[0]}`}
          </NavLink>
          <p className="text-[12px] font-semibold ml-4 self-center text-[#3ECDDD]">
            {locationArray[1]}
          </p>
        </div>
        <div className="h-1/2">
          <NavLink to={"/data-katalog"}>
            {({ isActive }) => (
              <div className="flex flex-col">
                <img
                  className="border-[#292929] border rounded hover:bg-[#3ecddd]"
                  src="/assets/icons/icon_datakatalog.svg"
                  alt=""
                  style={isActive ? activeStyleIcon : undefined}
                />
              </div>
            )}
          </NavLink>
        </div>
      </nav>
    </section>
  }

  return (
    <>
      {gisKort ? (
        getMapMenu()
      ) : (
        <>
          {getDesktopMenu()}
          {getMobileMenu()}
        </>
      )}

      <main className={`min-h-screen ${isShowBurgerMenu ? "hide-in-mobile":""}`}>  
        <Outlet context={allArticleItems} />      
      </main>
      {isShowBurgerMenu &&
        <div 
          className={`block md:hidden min-h-screen`}
          onClick={() => { setShowBurgerMenu(false) }}
        >
          {getMobileBurgerMenu()}
        </div>
      }
      <Footer />
    </>
  );
};

export default Header;
