import { useState, useEffect } from 'react'
import { fetchAsJson } from './Fetch.Service'
import { WpImage, WpPost, WpRender, WpTaxonomyTerm } from './WpApiCommonDataModels';

interface WpArticleCustomFields {
    title_gl: string;
    author_credit_da?: string;
    author_credit_gl?: string;
    content_da: string;
    content_gl: string;
    hero_image?: WpImage;
    summary_da: string;
    summary_gl: string;
    card_type: string;
    card__thumbnail_image?: WpImage;
    related?: number[];
    subject: WpTaxonomyTerm;
    data_portal: string[];
}

export interface WpArticle extends WpPost {
    title: WpRender;
    content: WpRender;
    acf: WpArticleCustomFields; 
}

interface FetchArticleItems {
    articles: WpArticle[];
    isPending: boolean;
    error?: any;
}

export const url = "/wp-json/wp/v2"
const articlesSlug = "articles"

export const useFetchArticleItems = (): FetchArticleItems => {
    const [articles, setArticles] = useState<WpArticle[]>([]);
    const [isPending, setIsPending] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        const abort = new AbortController();
        const dataBundleUrl = `${url}/${articlesSlug}?per_page=100`
        fetchAsJson(dataBundleUrl, abort).then(res => {
            const articles = res.data ?? []
            setArticles(articles)
            setIsPending(res.isPending)
            setError(res.error)
        })
        return () => abort.abort();
    }, []);

    return (
        { articles, isPending, error }
    )
}

