import { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import DataItemDownloadRow from './DataItemDownloadRow';
import { DownloadDataRowItem } from "./DataItemDownloadRow";
import NotFound404 from '../../NotFound404';
import Spinners from '../../Spinners';
import { WpDataBundle, fetchDataRowItems, WpDataItem } from '../../api/WpDataBundle';
import { wpDataBundleToDataBundlePageItem, wpDataItemToDownloadDataRowItem } from '../../api/ItemMapper';
import { useTranslation } from 'react-i18next';
import './DataBundlePage.css';


export interface DataBundleItem {
    id: number,
    title: string,
    summary: string,
    thumbnail: string,
    rows: number[],
    about: string,
    use: string,
    target_groups: string,
    terms: string,
    origin_and_quality: string,
    specifications: string,
    more_info: string,
    related_data_bundles: number[],
}

interface DataBundleInput {
    items: WpDataBundle[];
}

interface RowData {
    items: WpDataItem[];
    isPending: boolean;
}

const DataBundlePage = ({items}: DataBundleInput) => { 

    const { t } = useTranslation('translation', { keyPrefix: 'databundle' });
    const [rowData, setRowData] = 
        useState<RowData>({items: [], isPending: false})

    const abort = useRef(new AbortController());

    // TODO remove render counter
    const count = useRef(0);
    useEffect(() => {
        count.current = count.current + 1;
        console.warn(`rendered ${count.current} time`)
    });

    const route = useLocation();

    if(!items.length){
        return (<></>);
    }
    if(rowData.isPending){
        return (<div className='loading'><Spinners /></div>);
    }

    const pathSplit = route.pathname.split("/");
    const id = pathSplit[pathSplit.length-1];
    const dbItem = items.find(item => item.id.toString() === id) as WpDataBundle;
    if(!dbItem){
        return (<NotFound404 />);
    }

    const item = wpDataBundleToDataBundlePageItem(dbItem);
    
    const isRequireFetch = item.rows && !rowData.items.length && !rowData.isPending
    if (isRequireFetch) {
        setRowData({items: [], isPending: true});

        fetchDataRowItems(item.rows, abort.current).then(rows => {
            setRowData({items: rows, isPending: false});
            console.log("fetched rows items:", rows); // TODO remove
        })

    }

    let rows: DownloadDataRowItem[] = []
    rowData.items.forEach((dataItem: WpDataItem) => {
        rows.push(wpDataItemToDownloadDataRowItem(dataItem));
    });

    return (
        <div>
            <div>
                <div className="item-top-container">
                    <div className='w60'>
                        <div className='thumbnail-container'>
                            <img src={item.thumbnail} alt='Thumbnail image placeholder'></img>
                        </div>
                        <div className='summary-container'>
                            <h1 dangerouslySetInnerHTML={{__html:item.title}}></h1>
                            <div dangerouslySetInnerHTML={{__html:item.summary}}></div>
                        </div>
                    </div>
                    <div className='w40 map-container'>
                        <div className='h100'>
                            Map placeholder
                            {/* <iframe src={mapUrl} title="map placeholder"></iframe> */}
                        </div>
                    </div>
                </div>


                {rows.length && 
                <div className ="download-table-container">

                    <table>
                        <thead>
                            <tr>
                                <th className='col1'><div className='table-name'>{t("title")}</div></th>
                                <th>{t("format")}</th>
                                <th>{t("area")}</th>
                                <th>{t("coordinateSys")}</th>
                                <th>{t("size")}</th>
                                <th>{t("date")}</th>
                                <th className='col7'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, i:number) => {
                                return (
                                    <DataItemDownloadRow key={i} row={row}></DataItemDownloadRow>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                }
                <div className='accordion-container'>
                    <h1>Accordion component placeholder</h1>
                    <div className='description-container'>
                        <h2>{t("about")}</h2>
                        <div dangerouslySetInnerHTML={{__html:item.about}} />
                    </div>
                    <div className='description-container'>
                        <h2>{t("use")}</h2>
                        <div  dangerouslySetInnerHTML={{__html:item.use}} />
                    </div>
                    <div className='description-container'>
                        <h2>{t("targetGroups")}</h2>
                        <div dangerouslySetInnerHTML={{__html:item.target_groups}} />
                    </div>
                    <div className='description-container'>
                        <h2>{t("terms")}</h2>
                        <div dangerouslySetInnerHTML={{__html:item.terms}} />
                    </div>
                    <div className='description-container'>
                        <h2>{t("originAndQuality")}</h2>
                        <div dangerouslySetInnerHTML={{__html:item.origin_and_quality}} />
                    </div>
                    <div className='description-container'>
                        <h2>{t("specifications")}</h2>
                        <div dangerouslySetInnerHTML={{__html:item.specifications}} />
                    </div>
                    <div className='description-container'>
                        <h2>{t("moreInfo")}</h2>
                        <div dangerouslySetInnerHTML={{__html:item.more_info}} />
                    </div>                    
                </div>
                <div className='description-container'>
                    <h1>{t("relatedData")}</h1>
                </div>
            </div>

        </div>
    )
}
export default DataBundlePage