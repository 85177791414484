import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

interface OthersMenuInput {
  isMobile: boolean;
  changeLanguage: Function;
}

const OthersMenu = ({isMobile, changeLanguage}: OthersMenuInput) => {
    const { t } = useTranslation("translation", { keyPrefix: "header" });

  let activeStylePage = {
    color: "#3ecddd",
    background: "black",
  };

  return (
    <nav className={`flex ${isMobile?"flex-col pr-2 pl-2":"items-center gap-2 lg:gap-8 mx-2 xl:mx-10 pr-5 lg:pr-10"}`}>
      <NavLink 
        className={`p-1.5 ${isMobile?"bg-[#292929] hover:bg-black":""}`} 
        style={({ isActive }) => isActive ? activeStylePage : undefined }
        to="/kontakt-os"
      >
        {({ isActive }) => (
        <div className={`flex ${isMobile?"flex-row":"flex-col items-center"} cursor-pointer`}>
          <img src="/assets/icons/icon_info.svg" alt="" className={`${isMobile?"p-[4px]":""}`} />
          <label 
            className={`${isMobile?"pl-3":"font-light text-xs"}`}
            style={isActive ? activeStylePage : undefined }
          >
            {t("about")}</label>
        </div>
        )}
      </NavLink>

      <div
        className={`flex ${isMobile?"flex-row bg-[#292929] hover:bg-black":"flex-col"} items-center cursor-pointer p-1.5`}
        onClick={() => changeLanguage()}
      >
        <img src="/assets/icons/icon_flag.svg" alt="" className={`${isMobile?"p-[4px]":""}`}/>
        <label 
            className={`${isMobile?"pl-3":"font-light text-xs"}`}
        >
            {t("language")}
        </label>
      </div>
    </nav>
  );
};

export default OthersMenu;
