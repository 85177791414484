
const Giskort = (props) =>{

  const {appID} = props
  return (
    <main className='items-center'>
      <iframe  className='w-full h-[calc(100vh-82px)]' src={`${appID}`} title="App"/>
    </main>
  )
  }
  
  export default Giskort ;
  
