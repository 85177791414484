import { useState } from 'react'
import DataCard, { DataCardItem } from './DataCard';
import { useTranslation } from 'react-i18next';
import SubjectFilter from './SubjectFilter';
import CategoryFilter from './CategoryFilter';
import FormatFilter from './FormatFilter';

interface DataSearchInput {
    cards: DataCardItem[];
}

const DataSearch = ({ cards }: DataSearchInput) => {
    const [searchTerm, setSearchTerm]: any = useState('')
    const [categories, selectCategories]: any = useState<number[]>([]); 
    const [subjects, selectSubjects]: any = useState<number[]>([]); 
    const [formats, selectFormats]: any = useState<number[]>([]);
    const { t } = useTranslation('translation', { keyPrefix: 'datacatalog' });

    const searchlabel = t("searchlabel");

    return (
        <div className='search-gallery-root'>
            <aside className='search-controls'>
                <input type="text" title={searchlabel} placeholder={searchlabel} onChange={(e) => { setSearchTerm(e.target.value)}} />
                <CategoryFilter
                    changeCategories={(subjects: number[]) => { selectCategories(subjects) }}
                ></CategoryFilter>
                <SubjectFilter 
                    changeSubjects={(subjects: number[]) => { selectSubjects(subjects)}}
                ></SubjectFilter>
                <FormatFilter
                    changeFormats={(formats: number[]) => { selectFormats(formats)}}
                ></FormatFilter>
            </aside>

            <div className='gallery-board gallery-list' >
                {cards && cards.filter((item: DataCardItem) => {
                    if (!searchTerm && !subjects.length && !formats.length && !categories.length) {
                        return cards
                    } else if (
                        (
                            !searchTerm
                            || item.title.toLowerCase().includes(searchTerm.toLowerCase())
                            || item.body.toLowerCase().includes(searchTerm.toLowerCase())
                        ) && (
                            !subjects.length || subjects.some((subject:number)=>item.subjects.includes(subject))
                        ) && (
                            !formats.length || formats.some((format:number)=>item.formats.includes(format))
                        ) && (
                            !categories.length || categories.some((cat:number)=>item.categories.includes(cat))
                        )
                    ) {
                        return item
                    }
                }).map((item: DataCardItem) => (
                    <DataCard key={item.id} item={item}></DataCard>
                ))}
            </div>
        </div>
    )
}

export default DataSearch