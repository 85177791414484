import { useTranslation } from "react-i18next";

export interface DownloadDataRowItem {
    id: number,
    title: string;
    subtitle?: string;
    format: string;
    area?: string;
    coordinateSys: string;
    size?: string;
    date?: string;
    url: string;
}

interface DownloadRowInput {
    row: DownloadDataRowItem;
}

const DataItemDownloadRow = ({row}: DownloadRowInput) => {

    const { t } = useTranslation('translation', { keyPrefix: 'databundle' });
    const openUrl = (url: string) => {
        window.open(url);
    };

    return (
        <tr>
            <td className='col1'>
                <span dangerouslySetInnerHTML={{__html:row.title}}></span>
                {row.subtitle && <><br/><span dangerouslySetInnerHTML={{__html:row.subtitle}}></span></>}
            </td>
            <td>{row.format}</td>
            <td>{row.area}</td>
            <td>{row.coordinateSys}</td>
            <td>{row.size}</td>
            <td>{row.date}</td>
            <td className='col7'>
                <button className="download-button" onClick={() => {
                    openUrl(row.url)
                }}>
                    {t("download")}
                </button>
            </td>
        </tr>
    )
}
export default DataItemDownloadRow