
export interface FetchDataModel {
    data?: any;
    isPending: boolean;
    error?: any;
}

export const fetchAsJson = (url: string, abort: AbortController): Promise<FetchDataModel> => {
    console.log("fetchAsJson:", url)
    return fetch(url, { signal: abort.signal })
        .then(res => {
            if (!res.ok) {
                throw Error('could not fetch this resource');
            }
            return res.json();
        })
        .then(dat => {
            return ({data: dat, isPending: false, error: null})
        })
        .catch((err) => {
            if (err.name === 'AbortError') {
                console.log('AbortError')
                return ({data: null, isPending: false, error: null})
            } else {
                console.error(err);
                return ({data: null, isPending: false, error: err})
            }
        })
}