import { useTranslation } from "react-i18next";
import Heroaction from './Heroaction'
import {getSubjectColor, getSubjectIcon} from "../getSubjectColor"

interface HerotextInput {
  logo?: string;
  text?: string;
  action?: string;
  interest?: boolean
}

const subjectMap : subjectType = {
  "baeredygtig-udvikling" : "sustainability",
  "digitalisering-og-innovation" : "innovation",
  "uddannelse-og-kulturarv" : "education",
  "erhverv-og-vaekst" : "business" ,
  "forsyning" : "supply" ,
  "infrastruktur-og-bolig" : "infrastructure",
  "klima-og-beredskab" : "climate",
  "landsplanlaegning" : "planning",
  "miljo-og-natur" : "nature",
  "sundhed-og-forebyggelse" : "health"
}

interface subjectType {
  [key: string]: string;
}

function getColor(logo: string) {
  const lookup = logo ? logo : ""
  const slugInMap = Object.keys(subjectMap).includes(logo);
  const remap = subjectMap[`${lookup}`]
  return slugInMap ? `${getSubjectColor(remap)}` : "#d3d3d3"
}

function getLogo(logo: string) {
  const lookup = logo ? logo : ""
  const slugInMap = Object.keys(subjectMap).includes(logo);
  const remap = subjectMap[`${lookup}`]
  return slugInMap ? `${getSubjectIcon(remap)}` : "/assets/icons/icon_flag.svg"
}

const Herotext = (props: HerotextInput) =>{

  const {logo, text, action, interest} = props

  const { t } = useTranslation("translation", { keyPrefix: "header"});

  const pagetitel = logo ? logo : ""
  const herotitel = t(pagetitel);

    return (
      <section className='Hero-text-bg relative flex -top-16 w-full border-t border-[#292929] h-fit justify-center'>
        <div className="flex flex-start justify-between items-center flex-col sm:flex-row  max-w-5xl mt-8 w-full gap-2 mx-4  text-center ">
        {interest ? <img className="w-[40px] h-[40px] rounded" style={{background: `${getColor(pagetitel)}`}} src={`${getLogo(pagetitel)}`} alt="" /> : null}
        <div className="md:flex md:flex-row flex flex-col md:justify-center md:items-center w-full gap-2 text-center  sm:text-left md:text-center">
          {logo ? <div className="text-2xl font-semibold m-auto">{herotitel}</div> : <div></div>}
          {text ? <div className='m-auto w-3/4 max-h-[120px] overflow-hidden text-ellipsis'>{text}</div> : <div></div>}
        </div>
        {action ? <Heroaction navDestination={action}/> : <div></div>}
        </div>
      </section>
    )
  }
  
  export default Herotext ;
  

