import ArticleCardStandard from '../cards/ArticleCardStandard'
import ArticleCardArchive from '../cards/ArticleCardArchive'
import ArticleCardImage from '../cards/ArticleCardImage'
import { ArticleItem } from '../article/Article'
import { useOutletContext } from 'react-router'
import { WpDataBundle } from '../api/WpDataBundle'
import DataCard, { DataCardItem } from '../datacatalog/DataCard';
import { wpDataBundleToDataCardItem } from '../api/ItemMapper'
import { useTranslation } from 'react-i18next'


interface CardLayoutInput {
    firstrow: number[];
    secondrow: number[];
    thirdrow: number[];
    relatedbundles: number[];
    databundles: WpDataBundle[];
}

const CardLayout = (itemIDs: CardLayoutInput) =>{

    const { t: tWarn } = useTranslation("translation", { keyPrefix: "warning" });
    const { t } = useTranslation("translation", { keyPrefix: "databundle" });

    const {firstrow, secondrow, thirdrow, relatedbundles, databundles} = itemIDs
    const articles: ArticleItem[] = useOutletContext();

    const firstRowItems = articles.filter( article => firstrow.includes(article.id));
    const secondRowItems = articles.filter( article => secondrow.includes(article.id));
    const thirdRowItems = articles.filter( article => thirdrow.includes(article.id));
    const relatedbundleItems = databundles.filter( bundle => relatedbundles.includes(bundle.id));

    let cards: DataCardItem[] = []
    if(relatedbundleItems && relatedbundleItems.length > 0){
        relatedbundleItems.forEach(item => {
            cards.push(wpDataBundleToDataCardItem(item));
        });
    }

    return (
        <>
           <section className="flex flex-col mx-4 md:mx-0 md:grid md:grid-cols md:grid-cols-12 gap-4 max-w-5xl mb-8">
                {firstRowItems.map((cardinfo, i) => {
                    switch (cardinfo.cardType) {
                        case "Standard":
                            return(<ArticleCardStandard key={i} info={cardinfo} colspan={12/firstRowItems.length}/>)
                        case "image_card":
                            return(<ArticleCardImage key={i} info={cardinfo} colspan={12/firstRowItems.length}/>)
                        case "archive_card":
                            return(<ArticleCardArchive key={i} info={cardinfo} colspan={12/firstRowItems.length}/>)
                        default:
                            break;
                     }}
                    )
                }
                         {secondRowItems.map((cardinfo, i) => {
                    switch (cardinfo.cardType) {
                        case "Standard":
                            return(<ArticleCardStandard key={i} info={cardinfo} colspan={12/secondRowItems.length}/>)
                        case "image_card":
                            return(<ArticleCardImage key={i} info={cardinfo} colspan={12/secondRowItems.length}/>)
                        case "archive_card":
                            return(<ArticleCardArchive key={i} info={cardinfo} colspan={12/secondRowItems.length}/>)
                        default:
                            break;
                     }}
                    )
                }
                         {thirdRowItems.map((cardinfo, i) => {
                    switch (cardinfo.cardType) {
                        case "Standard":
                            return(<ArticleCardStandard key={i} info={cardinfo} colspan={12/thirdRowItems.length}/>)
                        case "image_card":
                            return(<ArticleCardImage key={i} info={cardinfo} colspan={12/thirdRowItems.length}/>)
                        case "archive_card":
                            return(<ArticleCardArchive key={i} info={cardinfo} colspan={12/thirdRowItems.length}/>)
                        default:
                            break;
                     }}
                    )
                }
            </section>

            {cards.length > 0 ? <section className=" w-full bg-[#141414] items-center pt-10 pb-10">
                <div className="flex flex-col max-w-6xl justify-center m-auto gallery-list">
                <h3 className='text-2xl mb-10'>{t("relatedData")}</h3>
                <div className="flex flex-row flex-wrap gap-4 justify-center mb-10">
                    {cards.map((item) => (
                       <DataCard key={item.id} item={item}></DataCard>
                    ))}
                </div>
                    <h4 className='text-lg'>{tWarn("warnh4")}</h4>
                    <p className='text-sm'>{tWarn("warnp")}</p>
                </div>
            </section> : null} 
        </>
    )
  }
  
  export default CardLayout;




