import Herotext from "../../layout/hero/Herotext";
import CardLayout from "../../layout/CardLayout";
import { useTranslation } from "react-i18next";
import { useFetchLandingpageItems } from "../../api/WpLandingpages";
import { wpLandingPagetoLandingPageItem } from "../../api/ItemMapper";
import { WpDataBundle } from "../../api/WpDataBundle";
import Hero from "../../layout/hero/Hero";
import Spinners from "../../Spinners";



interface DataCatalogInput{
  items: WpDataBundle[];
}

export interface LandingPageItem {
  title: string;
  slug: string;
  summary: string;
  featured_first: number[];
  featured_second: number[];
  featured_third: number[];
  featured_databundles: number[];
  hero_image: string;
}

export interface LinkCardItem {
    id: number;
    slug: string;
    title: string;
    content: string;
    summary: string;
    subject: string;
    subjectSlug: string;
    related: number[];
    thumbnail?: string;
    portals: string[];
    cardType: string;
    date: string;
    url_link: string;
    image_card_thumbnail: string;
    archive_select_subject: string;
    archive_featured_articles: number[];
}

const ForsidePage = ({ items }: DataCatalogInput ) => {
  const { landingpage, isPending, error } = useFetchLandingpageItems();

  const { t } = useTranslation("translation", { keyPrefix: "home" });

  const frontpageInfo = landingpage.find(pages => pages.slug === "frontpage" )  
  const pagedata = wpLandingPagetoLandingPageItem(frontpageInfo)
  const heroLabel = pagedata ? pagedata.summary : "Loading..."

  const firstRowID = pagedata?.featured_first ? pagedata?.featured_first : [] 
  const secondRowID = pagedata?.featured_second ? pagedata?.featured_second : []
  const thirdRowID = pagedata?.featured_third ? pagedata?.featured_third : []
  const relatedDataID = pagedata?.featured_databundles ? pagedata?.featured_databundles : []

  const imageURL = frontpageInfo?.acf.hero_image ? frontpageInfo?.acf.hero_image : "";

  return (
    <>
      {!pagedata && <div className='loading'><Spinners /></div>}
      <main className=" bg-[#222222] text-white min-h-screen flex flex-col items-center mx-auto w-full justify-between">
        <Hero imageURL={imageURL} />
        <Herotext text={heroLabel} />
        <CardLayout  firstrow={firstRowID} secondrow={secondRowID} thirdrow={thirdRowID} relatedbundles={relatedDataID} databundles={items}/>
      </main>
    </>
  );
};

export default ForsidePage
