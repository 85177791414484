import { NavLink, useParams } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import React from "react";
import CardLayout  from "../layout/CardLayout";
import BreadcrumbRibbon from "../layout/BreadcrumbRibbon";
import { useTranslation } from 'react-i18next'

const Hero = React.lazy(() => import("../layout/hero/Hero"));

export interface ArticleItem {
    slug: string;
    id: number;
    title: string;
    author?: string;
    content: string;
    summary: string;
    heroImage?: string;
    subject: string;
    subjectSlug: string;
    related: number[];
    thumbnail?: string;
    portals: string[];
    cardType: string;
    date: string;
    url_link: string;
    image_card_thumbnail: string;
    archive_select_subject: string;
    archive_featured_articles: number[];
}

type ArticleParams = {
    articleId: string;
}

function Article() {

  const { t } = useTranslation("translation", { keyPrefix: "article" });
  const articles: ArticleItem[] = useOutletContext();
  const { articleId } = useParams<ArticleParams>();

  let article = articles.find(
    (article) => article?.id?.toString() === articleId?.toString()
  );

  const imageURL = article?.heroImage ? article.heroImage  : "/assets/hero/img-forside.jpg";

    const epoch = Date.parse(article?.date ? article.date : "")
    const created = new Date(epoch)  

    const date = created.toLocaleDateString("en-DK")

  return (
    <>
      {article && (
        <div className="bg-white flex flex-col items-center justify-center m-auto">
          <BreadcrumbRibbon subject={article.subjectSlug} slug={article.subjectSlug}/>
          <Hero imageURL={imageURL} />
          <section className=" break-words px-4 md:mx-0 w-full sm:max-w-[786px] self-center">
            <article className="text-left mb-5 mx-4 md:mx-0">
            <aside className="text-left mt-10 bg-white text-black font-light text-xs border-b border-[#3ECDDD] w-full"> {date} {article?.author ? `- ${article.author}` : ""} </aside>

              <h1
                className=" text-3xl md:text-5xl pt-10 text-black"
                dangerouslySetInnerHTML={{ __html: article?.title }}
              ></h1>
              <div
                className="pb-10 [&>*]:text-black [&>*>*]:text-black [&>*>*>*]:text-black [&>*>*>*>*]:text-black [&>*>*>*>*>*]:text-black"
                dangerouslySetInnerHTML={{
                  __html: article?.content,
                }}
              ></div>
            </article>
           
            <aside className="flex text-left px-4 sm:m-auto py-5  justify-self-start bg-white text-black font-light text-xs  border-t border-[#3ECDDD] align-baseline ">
            <p className="text-black font-semibold pr-2">{`${t(`readMore`)}:`}</p>
           <NavLink className={"capitalize hover:font-bold"} to={"/arkiv"}>{t("allArticles")}</NavLink>
              {article.subject ? <>
              <p className="text-black px-3 "> {"/"} </p>
            <NavLink className={"capatilize hover:font-bold"} to={`/arkiv/${article.subject.replaceAll(" ", "-").replaceAll("&amp;", "og").toLowerCase()}`}>{t(`${article.subjectSlug}`)}</NavLink> </>
            : null}
           </aside>
          </section>

        </div>
      )}
      
      <div className="m-auto justify-center items-center flex flex-col pb-20 bg-[#222222]">
        <h2 className="text-2xl font-semibold py-10">{t("related")}</h2>
      <CardLayout firstrow={article?.related ? article.related : []} secondrow={[]} thirdrow={[]} relatedbundles={[]} databundles={[]}/>
      </div>
    </>
  );
}

export default Article;
