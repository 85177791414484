const colorMap = {
    "sustainability" : "#b03edd",
    "innovation" : "#603edd",
    "education" : "#3e51dd",
    "business" : "#3eddca",
    "supply" : "#3ea0dd",
    "infrastructure" : "#3ed5dd",
    "climate" : "#3e86dd",
    "planning" : "#953edd",
    "nature" : "#3ea0dd",
    "health" : "#3e6bdd"
}

const iconMap = {
    "sustainability" : "/assets/icons/icon_baeredygtig.svg",
    "innovation" : "/assets/icons/icon_digitalisering.svg",
    "education" : "/assets/icons/icon_uddannelse.svg",
    "business" : "/assets/icons/icon_erhverv.svg",
    "supply" : "/assets/icons/icon_forsyning.svg",
    "infrastructure" : "/assets/icons/icon_infrastruktur.svg",
    "climate" : "/assets/icons/icon_klima.svg",
    "planning" : "/assets/icons/icon_landsplan.svg",
    "nature" : "/assets/icons/icon_miljoe.svg",
    "health" : "/assets/icons/icon_sundhed.svg"
}

function getSubjectColor(slug: string) {
    const slugInMap = Object.keys(colorMap).includes(slug);
    return slugInMap ? `${colorMap[slug as keyof typeof colorMap]}` : "#d3d3d3"
}

function getSubjectIcon(slug: string) {
    const slugInMap = Object.keys(iconMap).includes(slug)
    return slugInMap ? `${iconMap[slug as keyof typeof iconMap]}` : "/assets/icons/icon_search.svg"
}

export  {getSubjectColor, getSubjectIcon}