import { useTranslation } from "react-i18next";
import { NavLink, useOutletContext, useParams } from "react-router-dom";
import { ArticleItem } from "../../article/Article";


const Archive = () => {
 
  const { t } = useTranslation("translation", { keyPrefix: "article" });
  const articles: ArticleItem[] = useOutletContext();
  const archiveFilter = useParams();

  const filterText = archiveFilter?.interest ? archiveFilter.interest.split("-") : []
  const articlAlleData = articles.filter(data => data.cardType === "Standard")

  let articlesToRender = []

  if(filterText.length > 0){
    const filter =  filterText[0]
    const filteredArticles = articlAlleData.filter(subjectFilter => subjectFilter.subject.split(" ")[0].toLowerCase() === filter )
    articlesToRender = filteredArticles
  } else{
    articlesToRender = articlAlleData
  }
  const archiveCardSlug = articlesToRender[0].subjectSlug
  return (
    <div className={"bg-white w-full text-black "}>
  <div className="bg-white flex flex-col items-center justify-start h-full min-h-screen w- mx-4 max-w-5xl  lg:m-auto">
  <aside className="flex text-left pb-5 my-10 w-full bg-white text-black font-light text-xs  border-b border-[#3ECDDD]">
  <p className="text-black font-semibold pr-2">{`${t(`readMore`)}:`}</p>
    <NavLink  className={"capitalize hover:font-bold"} to={"/arkiv"}>{t("allArticles")}</NavLink>
     {archiveFilter.interest ? <>
     <p className="text-black mx-3 "> {"/"} </p>
     <NavLink className={"capitalize hover:font-bold"} to={`/arkiv/${archiveFilter.interest}`}>{t(archiveCardSlug)}</NavLink> </>
     : null}
  </aside>
  <section className="max-w-5xl ">
    {articlesToRender.map((data,i) => { 
      return (
    <NavLink key={i}  to={`/article/${data.id}`}> 
    <article className=" border-b border-[#3ECDDD] mb-5  pb-5 text-left">
      <h1 className='text-black font-semibold text-2xl pb-4'>{data.title}</h1>
      <p  className='text-black'>{data.summary}</p>
    </article>
    </NavLink>
    )
  })}
  </section>
</div>
</div>
);
};

export default Archive;
