import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "footer" });

  return (
    <footer className="max-w-8xl bg-black border-t border-[#ffffff30] h-[160px] items-center m-auto mx-4">
      <div className="flex flex-row max-w-5xl items-center justify-between m-auto h-full">
        <section className="hidden sm:block">
          <img
            src={"/assets/icons/nunaGIS_Logo_Big.svg"}
            className=""
            alt="Nunagis logo"
          />
        </section>
        <div className="hidden sm:block h-12 border-l border-[#292929] mx-0"></div>
        <NavLink to="/kontakt-os">
          <section className=" flex flex-col h-full max-w-[150px] ">
            {
              <img
                className="h-[30px]"
                src="/assets/icons/icon_mail.svg"
                alt=""
              />
            }
            {<p className="text-[#3ECDDD] text-xs">{t("contact")}</p>}
            {<p className="text-[#8FB1B599] text-[10px]">{t("contactsub")}</p>}
          </section>
        </NavLink>
        <div className="h-12 border-l border-[#292929] mx-0"></div>

        <NavLink to="/brugerpanel">
          <section className="flex flex-col h-full max-w-[150px]">
            {
              <img
                className="h-[30px]"
                src="/assets/icons/icon_userteam.svg"
                alt=""
              />
            }
            {<p className="text-[#3ECDDD] text-xs">{t("userpanels")}</p>}
            {
              <p className="text-[#8FB1B599] text-[10px]">
                {t("userpanelssub")}
              </p>
            }
          </section>
        </NavLink>

        
      </div>
    </footer>
  );
};

export default Footer;
