import React from 'react'
import Herotext from '../../layout/hero/Herotext'
import CardLayout from '../../layout/CardLayout'
import { useFetchLandingpageItems } from '../../api/WpLandingpages';
import { wpLandingPagetoLandingPageItem } from '../../api/ItemMapper';
import { WpDataBundle } from '../../api/WpDataBundle';
import Spinners from '../../Spinners';

interface DataCatalogInput{
  items: WpDataBundle[];
}

const Hero = React.lazy(() => import("../../layout/hero/Hero"));

const KortPage = ({ items }: DataCatalogInput ) =>{

  const { landingpage } = useFetchLandingpageItems();

  const frontpageInfo = landingpage.find(pages => pages.slug === "kort" )  
  const pagedata = wpLandingPagetoLandingPageItem(frontpageInfo)
  const heroLabel = pagedata ? pagedata.summary : "Loading..."
  const imageURL = frontpageInfo?.acf.hero_image ? frontpageInfo?.acf.hero_image : "";

  const firstRowID = pagedata?.featured_first ? pagedata?.featured_first : [] 
  const secondRowID = pagedata?.featured_second ? pagedata?.featured_second : []
  const thirdRowID = pagedata?.featured_third ? pagedata?.featured_third : []
  const relatedDataID = pagedata?.featured_databundles ? pagedata?.featured_databundles : []

    return (
      <>
        {!pagedata && <div className='loading'><Spinners /></div>}
        <main className=" bg-[#222222] text-white min-h-screen flex flex-col items-center mx-auto w-full ">
          <Hero imageURL={imageURL}/>
          <Herotext logo={"map"} text={heroLabel} action={"/kort/giskort"}/>
          <CardLayout  firstrow={firstRowID} secondrow={secondRowID} thirdrow={thirdRowID} relatedbundles={relatedDataID} databundles={items}/>
        </main> 
      </>
    )
  }
  
  export default KortPage;