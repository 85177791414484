import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import "./Interessenav.css";

interface DataMenuInput {
  isMobile: boolean;
}

const DataMenu = ({isMobile}: DataMenuInput) => {
  const { t } = useTranslation("translation", { keyPrefix: "header" });

  let activeStyleIcon = {
    background: "#3ecddd",
  };

  let activeStylePage = {
    color: "#3ecddd",
    background: "black",
  };
  
  return (
    <nav className={`flex ${isMobile?"flex-col ":"gap-2 lg:gap-4"} justify-between pr-2 pl-2`}>
        <NavLink 
            className={`p-1.5 ${isMobile?"bg-[#292929] hover:bg-black":""}`} 
            to={"/arealansøgning"}
            style={({ isActive }) => isActive ? activeStylePage : undefined }
        >
            {({ isActive }) => (
                <div className={`flex ${isMobile?"flex-row":"flex-col"} items-center`}>
                    <img
                        className="border-[#292929] border rounded hover:bg-[#3ecddd]"
                        src="/assets/icons/icon_arealansoeg.svg"
                        alt=""
                        style={isActive ? activeStyleIcon : undefined}
                    />
                    <label 
                        className={`${isMobile?"pl-3":"font-light text-xs"}`}
                        style={isActive ? activeStylePage : undefined}
                    >
                        {t("arealapplication")}
                    </label>
                </div>
            )}
        </NavLink>
        <NavLink 
            className={`p-1.5 ${isMobile?"bg-[#292929] hover:bg-black":""}`} 
            to={"/data-katalog"}
            style={({ isActive }) => isActive ? activeStylePage : undefined }
        >
            {({ isActive }) => (
                <div className={`flex ${isMobile?"flex-row":"flex-col"} items-center`}>
                    <img
                        className="border-[#292929] border rounded hover:bg-[#3ecddd]"
                        src="/assets/icons/icon_datakatalog.svg"
                        alt=""
                        style={isActive ? activeStyleIcon : undefined}
                    />
                    <label 
                        className={`${isMobile?"pl-3":"font-light text-xs"}`}
                        style={isActive ? activeStylePage : undefined}
                    >
                        {t("datacatalog")}
                    </label>
                </div>
            )}
        </NavLink>
    </nav>
  )
};

export default DataMenu;
