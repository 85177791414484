import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import {getSubjectColor, getSubjectIcon} from "./getSubjectColor";

interface props {
    subject : string;
    slug: string;
}

interface urlLookup {
    sustainability: string;
    innovation: string;
    education: string;
    business: string;
    infrastructure: string;
    climate: string;
    planning: string;
    nature: string;
    health: string;
    supply: string;
}

function BreadcrumbRibbon({subject, slug} : props) {

    const { t } = useTranslation("translation", { keyPrefix: "article" });
    const color = getSubjectColor(slug)

    const urlLookup : urlLookup = {
        "sustainability": "baeredygtig-udvikling",
        "innovation": "digitalisering-og-innovation",
        "education": "uddannelse-og-kulturarv",
        "business": "erhverv-og-vaekst",
        "infrastructure": "infrastruktur-og-bolig",
        "climate": "klima-og-beredskab",
        "planning": "landsplanlaegning",
        "nature": "miljo-og-natur",
        "health": "sundhed-og-forebyggelse",
        "supply": "Forsyning",
    }   

    const slugInLookup = Object.keys(urlLookup).includes(slug)
    let interesseURL = slugInLookup ? `${urlLookup[slug as keyof typeof urlLookup]}` : "/"

    return(
        <div className="flex h-12 w-full justify-center bg-[#222222]">
            <div className="flex mx-4 md:mx-0 max-w-[786px] m-auto w-full justify-start items-start">
                <div className="flex align-baseline items-center gap-2">
                <img src={`${getSubjectIcon(slug)}`} alt={"Icon for subject"} className={`bg-[${color}] rounded`} style={{background: color}}/>
                <NavLink className={"hover:font-bold"} to={`/interesser/${interesseURL}`}>{ t(`${subject}`)}</NavLink>
                </div>
            </div>
        </div>
        )
    
        
    

}
export default BreadcrumbRibbon;