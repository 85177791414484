import { useTranslation } from "react-i18next";
import Hero from "../../layout/hero/Hero";


const ArealApplication = () => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'arealapplicationpage' });

  const imageURL = '/assets/hero/img-arealvise.png'

  return (
      <div className="flex flex-col items-center  w-full justify-between mx-4 md:mx-auto">
          <div className="hero-container hide-in-mobile">
              <Hero imageURL={imageURL}/>
          </div>
          <div className="header-container">
              <div className="svg-container">
                  <img
                      src="/assets/icons/icon_arealansoeg.svg"
                      alt=""
                  />
              </div>
              <h1>{t("title")}</h1>
              <p>{t("herolabel")}</p>
          </div>
          <section className="grid grid-cols md:grid-cols-2 gap-4 max-w-5xl">

            <article className={`areal-card mb-6 bg-[#141414] rounded-tl-lg relative`} style={{gridColumn:`span ${1} / span ${1}`}}>
              <div className="flex-row m-4 h-full">
                  <div className="w-14 overflow-hidden inline-block rounded-tl-lg absolute top-0 left-0">
                      <div className={"h-20  rotate-45 transform origin-top-right"} style={{backgroundColor: "#3ECDDD"}}>
                      </div>
                  </div>
                  <p className="text-start break-all text-xs mt-10 mb-2 text-[0.5rem]">{t("card_left_tag")}</p>
                  <h3 className="break-word text-base text-start font-bold mb-2">{t("card_left_label")}</h3>
                  <p className="break-word text-sm text-start w-full text-ellipsis overflow-hidden font-thin mb-2 text-[0.7rem]">
                        {t("card_left_content1")}
                  </p>
                  <ul className="list-disc pl-4 text-sm text-start w-full text-ellipsis overflow-hidden font-thin [&>*]:text-[0.7rem]">
                    <li>{t("card_left_content2")}</li>
                    <li>{t("card_left_content3")}</li>
                    <li>{t("card_left_content4")}</li>
                  </ul>
              </div>
            </article>

            <article className={`areal-card mb-6 bg-[#141414] rounded-tl-lg relative`} style={{gridColumn:`span ${1} / span ${1}`}}>
                <div className="flex-row m-4 h-full">
                    <div className="w-14 overflow-hidden inline-block rounded-tl-lg absolute top-0 left-0">
                        <div className={"h-20  rotate-45 transform origin-top-right"} style={{backgroundColor: "#3ECDDD"}}>
                        </div>
                    </div>
                    <p className="text-start break-all text-xs mt-10 mb-2 text-[0.5rem]">{t("card_right_tag")}</p>
                    <h3 className="break-word text-base text-start font-bold mb-2">{t("card_right_label")}</h3>
                    <p className="break-word text-sm text-start w-full text-ellipsis overflow-hidden font-thin mb-2 text-[0.7rem]">
                          {t("card_right_content")}
                    </p>
                </div>
            </article>

          </section>
          <div className='w-full items-center'>
            <iframe  className='w-full h-screen' src={`https://survey123.arcgis.com/share/40baf523f1ec4c90aedcfcb561177372?portalUrl=https%3A//kort.nunagis.gl/portal&locale=${i18n.language}`} title="App"/>
          </div>
      </div>
  )
};

export default ArealApplication;
