import { useTranslation } from "react-i18next";
import TopicSymbolLink from "./TopicSymbolLink";




const TopicSymbols = [
{
"url_gl": "https://kort.nunagis.gl/portal/sharing/rest/content/items/8129cdd3e1834d81b4f89d3f16f37981/data",
"url_dk":"https://kort.nunagis.gl/portal/sharing/rest/content/items/e02c7f10441540ab8203ee0b7036f3c1/data",
"svgPath": "Turisme",
 "svgLabelLookup": "tourism",
"status": true
},
{
"url_gl": "",
"url_dk":"",
"svgPath": "Raastof",
 "svgLabelLookup": "rawm",
"status": false
},
{
"url_gl": "",
"url_dk":"",
"svgPath": "Fiskeri",
 "svgLabelLookup": "fishing",
"status": false
},
{
"url_gl": "",
"url_dk":"",
"svgPath": "Fangst",
 "svgLabelLookup": "catch",
"status": false
},
{
"url_gl": "",
"url_dk":"",
"svgPath": "Landbrug",
 "svgLabelLookup": "landuse",
"status": false
},

{
"url_gl": "",
"url_dk":"",
"svgPath": "Lufthavne",
 "svgLabelLookup": "airports",
"status": false
},
{
"url_gl": "https://kort.nunagis.gl/portal/sharing/rest/content/items/e357f66d2ba9438eb12c07bd5b3f2e29/data",
"url_dk": "https://kort.nunagis.gl/portal/sharing/rest/content/items/f19f03e996bc47ad8c9ca6bcdbd1dfb6/data",
"svgPath": "Havne",
 "svgLabelLookup": "ports",
"status": true
},
{
"url_gl": "",
"url_dk":"",
"svgPath": "Telekommunikation",
 "svgLabelLookup": "tele",
"status": false
},
{
"url_gl": "",
"url_dk":"",
"svgPath": "Bolig",
 "svgLabelLookup": "housing",
"status": false
},
{
"url_gl": "https://kort.nunagis.gl/portal/sharing/rest/content/items/67d072f3f0d342b0af16f31e220cb6b6/data",
"url_dk": "https://kort.nunagis.gl/portal/sharing/rest/content/items/67d072f3f0d342b0af16f31e220cb6b6/data",
"svgPath": "Kollegier",
 "svgLabelLookup": "colleges",
"status": true
},
{
  "url_gl": "https://kort.nunagis.gl/portal/sharing/rest/content/items/e7c4746029da48d9985339a39dfbba2c/data",
"url_dk":"https://kort.nunagis.gl/portal/sharing/rest/content/items/ddb5a82207214f3e96b5c16d291774ab/data",
  "svgPath": "Affald",
  "svgLabelLookup": "trash",
  "status": true
  },
  {
  "url_gl": "https://kort.nunagis.gl/portal/sharing/rest/content/items/9cf84b7752eb49edbf83bbc8d0d749a1/data",
"url_dk": "https://kort.nunagis.gl/portal/sharing/rest/content/items/9cf84b7752eb49edbf83bbc8d0d749a1/data",
  "svgPath": "Energiforsyning",
  "svgLabelLookup": "energy",
  "status": true
  },
  {
  "url_gl": "",
"url_dk":"",
  "svgPath": "Drikkevand",
  "svgLabelLookup": "drinkingwater",
  "status": false
  },
  {
  "url_gl": "",
"url_dk":"",
  "svgPath": "Foerskole",
  "svgLabelLookup": "preschool",
  "status": false
  },
  {
  "url_gl": "",
"url_dk":"",
  "svgPath": "Folkeskole",
  "svgLabelLookup": "school",
  "status": false
  },
  {
 "url_gl": "",
"url_dk":"",
 "svgPath": "Videregaaende_udd",
 "svgLabelLookup": "furthereducation",
 "status": false
 },
 {
 "url_gl": "",
"url_dk":"",
 "svgPath": "Sundhed",
 "svgLabelLookup": "health",
 "status": false
 },
 {
 "url_gl": "",
"url_dk":"",
 "svgPath": "Aeldre",
 "svgLabelLookup": "elders",
 "status": false
 },
 {
 "url_gl": "",
"url_dk":"",
 "svgPath": "Doegninstitutioner",
 "svgLabelLookup": "daycare",
 "status": false
 },
 {
 "url_gl": "",
"url_dk":"",
 "svgPath": "Handicappede",
 "svgLabelLookup": "handicapped",
 "status": false
 },
]

const TopicSymbolLayout = () => {

    const { t } = useTranslation("translation", { keyPrefix: "sectorplanspage" });


return (
<section className="grid grid-cols grid-cols-4 md:grid-cols-10 gap-4 max-w-5xl w-full pt-10 pb-10 mb-10 mt-10 border-y" >
    {
        TopicSymbols.map((topic, i) => {
            return (<TopicSymbolLink key={i} url_dk = {topic.url_dk} url_gl = {topic.url_gl} svgPath = {topic.svgPath}  svgLabelLookup = {t(topic.svgLabelLookup)} status = {topic.status}/>)
        })
    }
</section>
);
  };
  
  export default TopicSymbolLayout;
  