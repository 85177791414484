import { getSubjectIcon, getSubjectColor } from "../getSubjectColor";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Tooltip from "./Tooltip";
import "./Interessenav.css";

interface InterestMenuInput {
  isMobile: boolean;
}

const Interessenav = ({isMobile}: InterestMenuInput) => {
  const { t } = useTranslation("translation", { keyPrefix: "header" });

  let activeStylePage = {
    color: "#3ecddd",
    background: "black",
  };

  const InteresseNavRoutes = [
    { slug: "business", label: "erhverv-og-vaekst" },
    { slug: "infrastructure", label: "infrastruktur-og-bolig" },
    { slug: "supply", label: "forsyning" },
    { slug: "nature", label: "miljo-og-natur" },
    { slug: "climate", label: "klima-og-beredskab" },
    { slug: "health", label: "sundhed-og-forebyggelse" },
    { slug: "education", label: "uddannelse-og-kulturarv" },
    { slug: "innovation", label: "digitalisering-og-innovation" },
    { slug: "planning", label: "landsplanlaegning" },
    { slug: "sustainability", label: "baeredygtig-udvikling" }
  ];

  const interests: JSX.Element[] = [];
  for (let i = 0; i < InteresseNavRoutes.length; i += 1) {
    const route = InteresseNavRoutes[i];
    const subjectColor = getSubjectColor(route.slug);
    const activeStyleIcon = {
      background: subjectColor,
    };

    interests.push(
      <li>
        <Tooltip key={i} text={t(route.slug)}>
          <NavLink key={i}
            to={`/interesser/${route.label}`}
            className={isMobile ? `flex flex-row p-1.5 cursor-pointer bg-[#292929] hover:bg-black hover:text-[#3ecddd]`: ""}
            style={({ isActive }) => isActive ? activeStylePage : undefined }
          >
            {({ isActive }) => (
              <>
                <div
                  key={i}
                  className={`border border-[#292929] rounded ${route.slug}`}
                  style={isActive ? activeStyleIcon : undefined}
                >
                  <img className="min-w-[32px]" src={getSubjectIcon(route.slug)} alt={route.label} />
                </div>
                {isMobile && 
                  <label 
                    style={ isActive ? activeStylePage : undefined } 
                    className="self-center cursor-pointer pl-3">{t(route.slug)}
                  </label>
                }
              </>
            )}
          </NavLink>
        </Tooltip>
      </li>

    );
  }

  return (
    <nav>
      {!isMobile &&
        <div className="flex flex-col items-center pr-2 pl-2 min-w-[185px]">
          <ol className="grid grid-cols-5 xl:grid-cols-10 pt-2 xl:pt-0 gap-2 items-center ">
            {interests}
          </ol>
          <span className="font-light text-xs pb-2 xl:pb-0">
            {t("interests")}
          </span>
        </div>
      }
      {isMobile &&
        <div className="flex flex-col items-center pr-2 pl-2">
          <ol className="gap-2 items-center w-full">
            {interests}
          </ol>
        </div>
      }

    </nav>
  );
};

export default Interessenav;
