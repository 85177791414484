import { useTranslation } from "react-i18next";
import { getFormats } from "../api/Taxonomy";

interface FormatFilterInput {
    changeFormats: Function;
}

const FormatFilter = ({changeFormats}: FormatFilterInput) => {
    const { t } = useTranslation('translation', { keyPrefix: 'datacatalog' });

    const allFormats = getFormats();
    
    const selectFormats = (value: number, selected: boolean) => {
        if(selected){
            changeFormats((prev :number[]) => [...prev, value])
        } else {
            changeFormats((prev :number[]) => prev.filter(format => format !== value))
        }
    }

    return (
        <div className='filter'>
            <p>{t("fileformats")}</p>
            {
                allFormats &&
                allFormats.map((tax, index) => {
                    return (
                        <div key={index}>
                            <input type="checkbox" 
                                id={'format' + index} 
                                onChange={(e) => { selectFormats(parseInt(e.target.value), e.target.checked)}}
                                name="format" 
                                value={tax.id} />
                            <label htmlFor={'format' + index}>{tax.name}</label>
                        </div>
                    );
                })
            }
        </div>
    )
}

export default FormatFilter