import { useState, useEffect } from 'react'
import { fetchAsJson } from './Fetch.Service'
import { WpImage, WpPost, WpRender, WpTaxonomyTerm } from './WpApiCommonDataModels';

interface WpLinkcardCustomFields {
    summary_dk: string;
    summary_gl: string;
    card_type: string;
    url_link: string;
    image_card_thumbnail: string;
    archive_select_subject: string;
    archive_featured_articles: number[];
    slug: string;
    id: number;
    title: string;
    content: string;
    summary: string;
    subject: WpTaxonomyTerm;
    subjectSlug: string;
    related: number[];
    thumbnail?: string;
    portals: string[];
    date: string;
}

export interface WpLinkcard extends WpPost {
    title: WpRender;
    slug: string;
    id: number;
    acf: WpLinkcardCustomFields;
}

interface FetchLinkcardItems {
    linkcard: WpLinkcard[];
    isPending: boolean;
    error?: any;
}

export const url = "/wp-json/wp/v2"
const linkcardSlug = "link_card"

export const useFetchlinkcardItems = (): FetchLinkcardItems => {
    const [linkcard, setlinkcard] = useState<WpLinkcard[]>([]);
    const [isPending, setIsPending] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        const abort = new AbortController();
        const dataBundleUrl = `${url}/${linkcardSlug}?per_page=100`
        fetchAsJson(dataBundleUrl, abort).then(res => {
            const page = res.data ?? []
            setlinkcard(page)
            setIsPending(res.isPending)
            setError(res.error)
        })
        return () => abort.abort();
    }, []);

    return (
        { linkcard, isPending, error }
    )
}

