import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import OfflineIcon from "./OfflineIcon";
import { getCategory, getSubject } from "../api/Taxonomy";

export interface DataCardItem {
    id: number,
    title: string,
    body: string,
    image: string,
    subjects: number[],
    formats: number[],
    categories: number[]
}

interface DataCardInput {
    item: DataCardItem;
}


const DataCard = ({ item }: DataCardInput) => {
    const navigate = useNavigate();
    const { t } = useTranslation('translation', { keyPrefix: 'datacard' });
    const { t: tSubject } = useTranslation('translation', { keyPrefix: 'subjectfilter' });
    const { t: tCategory } = useTranslation('translation', { keyPrefix: 'categoryfilter' });

    const openDescriptionPage = (item: any) => {
        const isDescriptionOffline = true;
        if (isDescriptionOffline) { // TODO: remove this when online
            return
        } 
        navigate(`/data-katalog/${item.id}`);
    }

    const getTaxonomyLabel = (item: DataCardItem): string => {
    
        let subjects: string[] = [];
        item.subjects.forEach(subjectId => {
            const subject = getSubject(subjectId);
            subjects.push(tSubject(subject.slug));
        });
    
        let categories: string[] = [];
        item.categories.forEach(categoryId => {
            const category = getCategory(categoryId);
            categories.push(tCategory(category.slug));
        })
        
        const subjectLabel = subjects.join(" + ");
        const catetoryLabel = categories.join(" + ");
        let label = subjectLabel && catetoryLabel ? `${catetoryLabel} / ${subjectLabel}` : (catetoryLabel ?? subjectLabel);
        return label;
    }

    const parser = new DOMParser();
    const title = parser.parseFromString(item.title, 'text/html')?.body?.textContent ?? item.title;

    return (
        <div className='gallery-preview flex-item text-start' 
            onClick={() => { openDescriptionPage(item) }}>
            <div>
            <svg className='svg-card' width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="3" width="21" height="8" rx="4" stroke="white"/>
                <path d="M19 7H16" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="2" y="14" width="21" height="8" rx="4" stroke="white"/>
                <path d="M19 18H16" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <div>
                <span></span>
            </div>
                {item?.image && 
                <img src={item?.image}/>}
                <div className={item?.image ? "card-item-tags" : "card-item-tags ml-6"}>{getTaxonomyLabel(item)}</div>
                <div className="card-item-title truncate" title={title}>{title}</div>
                <div className="card-item-snippet" title={item.body}>{item.body}</div>
            </div>
            <div className="text-center">
                <a className="offline button uppercase svg-container" target="_blank">{t("gotodata__OFFLINE")}
                    <OfflineIcon></OfflineIcon>
                </a>
            </div>
        </div>
    )
}

export default DataCard