import { useState, useEffect } from 'react'
import { fetchAsJson } from './Fetch.Service'
import { WpImage, WpPost, WpRender, WpTaxonomyTerm } from './WpApiCommonDataModels'


interface DataItemCustomFields {
    title_gl: string;
    subtitle_da: string;
    subtitle_gl: string;
    data_type: string;
    url: string;
    format: WpTaxonomyTerm[];
    area_da: string;
    area_gl: string;
    coordinate_system: string;
    size: string;
    date: string
}

export interface WpDataItem extends WpPost {
    title: WpRender;
    acf: DataItemCustomFields;
}

interface DataBundleCustomFields {
    title_gl: string;
    image: WpImage;
    summary_da: string;
    summary_gl: string;
    data_items: number[];
    category: number;
    subject: number;
    formats: number[];
    about_da: string;
    about_gl: string;
    use_da: string;
    use_gl: string;
    target_groups_da: string;
    target_groups_gl: string;
    terms_da: string;
    terms_gl: string;
    origin_and_quality_da: string;
    origin_and_quality_gl: string;
    specifications_da: string;
    specifications_gl: string;
    more_info_da: string;
    more_info_gl: string;
    related_data_bundles: number[];
}

export interface WpDataBundle extends WpPost {
    title: WpRender;
    author: number;
    featured_media: number;
    acf: DataBundleCustomFields;
}

interface FetchDataBundleItems {
    dataBundleItems: WpDataBundle[];
    isPending: boolean;
    error?: any;
}

export const url = "/wp-json/wp/v2"
const dataBundleSlug = "data_bundles"
const dataItemsSlug = "data_items"

export const useFetchDataBundleItems = (): FetchDataBundleItems => {
    const [dataBundleItems, setDataBundleItems] = useState<WpDataBundle[]>([]);
    const [isPending, setIsPending] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        const abort = new AbortController();
        const dataBundleUrl = `${url}/${dataBundleSlug}?per_page=100`
        fetchAsJson(dataBundleUrl, abort).then(res => {
            setDataBundleItems(res.data)
            setIsPending(res.isPending)
            setError(res.error)
        })
        return () => abort.abort();

    }, []);

    return (
        { dataBundleItems, isPending, error }
    )
}


export const fetchDataRowItems = (ids: number[], abort: AbortController): Promise<WpDataItem[]> => {
    if ( !ids || !ids.length ) {
        const emptyPromise = new Promise<WpDataItem[]>((resolve) => {
            resolve([]) 
        });
        return emptyPromise;
    }

    let idFilters: string[] = [];
    ids.forEach(id => {
        idFilters.push(`include[]=${id}`)
    });

    let dataItemUrl = `${url}/${dataItemsSlug}`

    if(idFilters.length) {
        const filter = idFilters.join("&")
        dataItemUrl = `${dataItemUrl}?${filter}`
    }

    const fetchPromise = fetchAsJson(dataItemUrl, abort).then(res => {
        const dataItems: WpDataItem[] = res.data;
        return dataItems;
    })

    return fetchPromise
}