import { ChangeEventHandler } from 'react'
import { WpTaxonomy } from '../api/WpApiCommonDataModels';
import { useTranslation } from 'react-i18next';
import { getSubjectColor, getSubjectIcon} from "../layout/getSubjectColor"
import './SubjectFilter.css';
import { getSubjects } from '../api/Taxonomy';

interface SubjectFilterInput {
    changeSubjects: Function;
}

const SubjectFilter = ({changeSubjects}: SubjectFilterInput) => {
    const { t } = useTranslation('translation', { keyPrefix: 'subjectfilter' });

    const allSubjects: WpTaxonomy[] = getSubjects();
    
    const selectSubjects = (value: number, selected: boolean) => {
        if(selected){
            changeSubjects((prev :number[]) => [...prev, value])
        } else {
            changeSubjects((prev :number[]) => prev.filter(subject => subject !== value))
        }
    }

    const checkboxes = allSubjects.map((tax, index) => (
        <div key={index} className="grid-item">
            <SubjectCheckbox 
                tax={tax}
                onChange={(e) => { selectSubjects(parseInt(e.target.value), e.target.checked)}}
                />
        </div>
      ));
    
  const rows = [];
  for (let i = 0; i < checkboxes.length; i += 2) {
    rows.push(
      <div key={i / 2} className="row">
        {checkboxes.slice(i, i + 2)}
      </div>
    );
  }

  return (
        <div className='filter'>
            <p>{t("label")}</p>
            <div className="grid">{rows}</div>
        </div>
    )
}

export default SubjectFilter


interface SubjectCheckboxInput {
    tax: WpTaxonomy;
    onChange: ChangeEventHandler<HTMLInputElement>;
}

const SubjectCheckbox = ({ tax, onChange }: SubjectCheckboxInput) => {
    const { t } = useTranslation('translation', { keyPrefix: 'subjectfilter' });
    const subjectColor =  getSubjectColor(tax.slug)
    return (
        <label className="checkbox-container">
            <input
                type="checkbox"
                onChange={onChange}
                name="subject" 
                value={tax.id}
            />
            <span className="icon" >
                <img style={{backgroundColor: subjectColor}} src={getSubjectIcon(tax.slug)} alt=""/>
                {t(tax.slug)}
            </span>
        </label>

    )
}
