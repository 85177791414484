import {Suspense} from 'react'
interface HeroInput {
  imageURL: string;
}

const Hero = (props: HeroInput) =>{

  const {imageURL} = props;

    return (
      <Suspense>
        <section className= 'h-96 w-full bg-cover bg-center' style={{"backgroundImage" : `url(${imageURL})`}}></section>
      </Suspense>
    )
  }
  
  export default Hero;
  

