import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import { ArticleItem } from "../article/Article";
import { getSubjectColor, getSubjectIcon } from "../layout/getSubjectColor"

interface ArticleCardStandardInput {
    info: ArticleItem;
    colspan: number;
}

const ArticleCardStandard = (props: ArticleCardStandardInput) =>{
    const {info, colspan} = props
    const { t } = useTranslation('translation', { keyPrefix: 'subjectfilter' });
    
    const cardSubjectSlug  = info.subjectSlug
    const subjectColor =  getSubjectColor(cardSubjectSlug)

    const epoch = Date.parse(info.date)
    const created = new Date(epoch)

    const isLandscape = colspan > 3;
    const orientationClass = isLandscape ? "landscape-orient" : "portrait-orient";
    const cardheight =  isLandscape ?  242 : 329;

    const parser = new DOMParser();
    const title = parser.parseFromString(info.title, 'text/html')?.body?.textContent ?? info.title;

    return (
        <article className={`mb-6 max-w-[330px] sm:max-w-[500px] md:w-full  bg-[#141414] rounded-tl-lg relative ${orientationClass}`} style={{gridColumn:`span ${colspan} / span ${colspan}`, height: `${cardheight}px` }}>
            <Link to={`/article/${info.id}`}>
                <div className="flex-col m-4 h-full">
                    <div className="w-14 overflow-hidden inline-block rounded-tl-lg absolute top-0 left-0">
                        <div className={"h-20  rotate-45 transform origin-top-right"} style={{backgroundColor: subjectColor}}>
                        </div>
                        <img className="absolute top-0" src={getSubjectIcon(cardSubjectSlug)} alt=""/>
                    </div>
                    <p className="text-end text-xs mb-2 pt-4 text-[#2d8d98]">{created.toLocaleDateString("en-DK")}</p>
                    <p className="text-start break-word text-[9px] font-medium mb-2 ">{t(info.subjectSlug)}</p>
                    <h3 className="break-word text-base text-start font-bold mb-2 truncate" title={title}>{title}</h3>
                    <p 
                        className={`card-item-snippet break-word text-start w-full p-0`}
                        title={info.summary}>
                            {info.summary}
                    </p>
                </div>
            </Link>
        </article>

    )
}

export default ArticleCardStandard;