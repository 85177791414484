import { NavLink, useNavigate } from "react-router-dom"
import { useOutletContext } from "react-router-dom";
import { LinkCardItem } from "../pages/forside/ForsidePage";
import { getSubjectColor, getSubjectIcon} from "../layout/getSubjectColor"
import { ArticleItem } from "../article/Article";
import { useTranslation } from "react-i18next";
import Spinners from "../Spinners";

interface ArticleCardArchiveInput {
    info: LinkCardItem;
    colspan: number;
}

const ArticleCardArchive = (props: ArticleCardArchiveInput) =>{
    const {info, colspan} = props
    const navigate = useNavigate();
    const { t } = useTranslation('translation', { keyPrefix: 'subjectfilter' });

    const articles: LinkCardItem[]  = useOutletContext();
    const relatedArticleIDs = info.archive_featured_articles;
    const relatedArticles = articles.filter( article => relatedArticleIDs.includes(article.id));
    if(!relatedArticles || !relatedArticles.length){
        return <div className='loading'><Spinners /></div>
    }
    const subjectColor =  getSubjectColor(relatedArticles?.[0].subjectSlug);

    const query = relatedArticles?.[0].subject.replace(" ", "-").toLowerCase();

    const cardheight =  colspan > 3 ?  242 : 329;

    const openDescriptionPage = (event: any) => {
        const mouseEvent = event as MouseEvent;
        const targetElement = mouseEvent.target as HTMLElement;
        if(targetElement?.tagName === "P" ){
            return;
        }
        navigate(`/arkiv/${query}`);
    }

    return (
        <div 
            className="cursor-pointer"
            onClick={(e) => { openDescriptionPage(e) }}
            style={{ gridColumn : `span ${colspan} / span ${colspan}`, height: `${cardheight}px` }}
        > 
            <article className="flex flex-col flex-nowrap bg-[#141414] rounded-tl-lg relative h-full max-w-[330px] sm:max-w-[500px] md:w-full">
                <div className="flex flex-col m-4 h-full">
                <div className="w-14 overflow-hidden inline-block rounded-tl-lg absolute top-0 left-0">
                        <div className={"h-20  rotate-45 transform origin-top-right"} style={{backgroundColor: subjectColor}}>
                        </div>
                        <img className="absolute top-0" src={getSubjectIcon(relatedArticles?.[0].subjectSlug)} alt=""/>
                    </div>
                    <p className="border-b pt-10 pb-2 text-left text-[9px]">{t(relatedArticles?.[0].subjectSlug)}</p>
                    {relatedArticles.map((relatedArticle, i) => {
                        return (
                            <NavLink key={i} className="max-h-[30%]" to={`/article/${relatedArticle.id}`}>    
                                <p
                                    className="py-2 text-sm text-start font-bold mb-2 border-b truncate"
                                    title={relatedArticle.title}
                                >
                                    {relatedArticle.title}
                                </p>
                            </NavLink>
                        )
                    })}

                </div>
                
            </article>
        </div>

    )
}

export default ArticleCardArchive;