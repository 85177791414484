
import { useTranslation } from 'react-i18next'



const BrugerPanel = () => {
  const { t } = useTranslation("translation", { keyPrefix: "brugerpanelpage" });


    return (
        <div className="bg-white flex flex-col items-center justify-start h-screen ">
          <aside className="text-left my-10 w-full bg-white text-black font-light text-xs max-w-[786px]  border-b border-[#3ECDDD] mx-4 sm:mx-0">  </aside>

          <section className="max-w-[786px] mx-4">
            <article className="text-left border-b border-[#3ECDDD] mb-10 h-full">
              <h1 className='text-black font-semibold text-2xl pb-4'>{t("titel")}</h1>
              <p  className='text-black'>{t("usercontent")}</p>
            </article>
          </section>
        </div>
    );
  };
  
  export default BrugerPanel;
  