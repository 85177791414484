import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Icon from "./Icons";

interface TopicSymbolInput {
    url_dk: string,
    url_gl: string,
    svgPath: string,
    svgLabelLookup: string,
    status: boolean
}

const TopicSymbolLink = (props: TopicSymbolInput ) => {

    const { t } = useTranslation("translation", { keyPrefix: "sectorplanspage" });

    const {url_dk, url_gl, svgPath, svgLabelLookup, status} = props
    const active = status ? "#3ecddd": "#606262"

    return (
        <div className={`cursor-pointer col-span-2 flex flex-col justify-center items-center content-between rounded`} >
            <Icon color={active} topic={svgPath}></Icon>

              <h3 className="font-medium text-base">{svgLabelLookup}</h3>
              <aside >
                {
                 status ?
                 <div className="flex">
                  {url_dk ? <a href={url_dk}  target="_blank" rel="noreferrer" className="col-span-2 px-2 text-xs font-medium hover:font-bold hover:text-[#3ECDDD]">DK</a> : null}
                  {url_gl ? <a href={url_gl}  target="_blank" rel="noreferrer" className="col-span-2 text-xs font-medium hover:font-bold hover:text-[#3ECDDD]">GL</a> : null}
                 </div>
                  : <h3 className="text-xs font-medium">{t("comingsoon")}</h3>
                }
              </aside>
        </div>
    );
  };
  
  export default TopicSymbolLink;
  