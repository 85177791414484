import { DataBundleItem as DataBundlePageItem } from "../datacatalog/DataBundle/DataBundlePage";
import { DownloadDataRowItem as DownloadDataRowItem } from "../datacatalog/DataBundle/DataItemDownloadRow";
import { DataCardItem } from "../datacatalog/DataCard";
import { WpDataBundle, WpDataItem } from "./WpDataBundle";
import { WpArticle } from "./WpArticle";
import { WpLandingpage } from "./WpLandingpages";
import { ArticleItem } from "../article/Article";
import { LandingPageItem } from "../pages/forside/ForsidePage";
import { WpLinkcard } from "./WpLinkcards";
import { LinkCardItem } from "../pages/forside/ForsidePage";



import i18n from 'i18next';

export const wpArticleToArticleItem = (article: WpArticle ): ArticleItem => {
    const isLangGL = i18n?.language === "gl";
    const articleItem: ArticleItem =
    {
        slug:article?.slug,
        id: article?.id,
        title: isLangGL ? article?.acf?.title_gl : article?.title?.rendered,
        author: isLangGL ? article?.acf?.author_credit_gl : article?.acf?.author_credit_da,
        content: isLangGL ? article?.acf?.content_gl : article?.acf?.content_da,
        summary: isLangGL ? article?.acf?.summary_gl : article?.acf?.summary_da,
        subject: article?.acf?.subject?.name,
        subjectSlug: article?.acf?.subject?.slug,
        related: article?.acf?.related ?? [],
        thumbnail: article?.acf?.card__thumbnail_image?.url,
        portals: article?.acf?.data_portal,
        cardType: article?.acf?.card_type,
        date: article?.date,
        url_link: "",
        heroImage: article?.acf?.hero_image?.url,
        image_card_thumbnail: "",
        archive_select_subject: "",
        archive_featured_articles: []
    }

    return articleItem;
} 

export const wpDataItemToDownloadDataRowItem = (dataItem: WpDataItem): DownloadDataRowItem => {
    const isLangGL = i18n?.language === "gl";
    let formats: string[] = [];
    if (dataItem?.acf?.format?.length){
        dataItem?.acf?.format.forEach(format => {
            formats.push(format.name)
        });
    }
    const rowItem: DownloadDataRowItem =
    {
        id: dataItem?.id,
        title: isLangGL ? dataItem?.acf?.title_gl : dataItem?.title?.rendered,
        subtitle: isLangGL ? dataItem?.acf?.subtitle_gl : dataItem?.acf?.subtitle_da,
        format: formats.join(", "),
        area: isLangGL ? dataItem?.acf?.area_gl : dataItem?.acf?.area_da,
        coordinateSys: dataItem?.acf?.coordinate_system,
        size: dataItem?.acf?.size,
        date: dataItem?.acf?.date,
        url: dataItem?.acf?.url
    }

    return rowItem;
}


export const wpDataBundleToDataBundlePageItem = (dataItem: WpDataBundle): DataBundlePageItem => {
    const isLangGL = i18n?.language === "gl";
    const rowItem: DataBundlePageItem =
    {
        id: dataItem?.id,
        title: isLangGL ? dataItem?.acf?.title_gl : dataItem?.title?.rendered,
        summary: isLangGL ? dataItem?.acf?.summary_gl : dataItem?.acf?.summary_da,
        thumbnail: dataItem?.acf?.image?.url,
        rows: dataItem?.acf?.data_items,
        about: isLangGL ? dataItem?.acf?.about_gl : dataItem?.acf?.about_da,
        use: isLangGL ? dataItem?.acf?.use_gl : dataItem?.acf?.use_da,
        target_groups: isLangGL ? dataItem?.acf?.target_groups_gl : dataItem?.acf?.target_groups_da,
        terms: isLangGL ? dataItem?.acf?.terms_gl : dataItem?.acf?.terms_da,
        origin_and_quality: isLangGL ? dataItem?.acf?.origin_and_quality_gl : dataItem?.acf?.origin_and_quality_da,
        specifications: isLangGL ? dataItem?.acf?.specifications_gl : dataItem?.acf?.specifications_da,
        more_info: isLangGL ? dataItem?.acf?.more_info_gl : dataItem?.acf?.more_info_da,
        related_data_bundles: dataItem?.acf?.related_data_bundles,
    }

    return rowItem;
}


export const wpDataBundleToDataCardItem = (dataItem: WpDataBundle): DataCardItem => {
    const isLangGL = i18n?.language === "gl";
    const cardItem: DataCardItem =
    {
        id: dataItem?.id,
        title: isLangGL ? dataItem?.acf?.title_gl : dataItem?.title?.rendered,
        body: isLangGL ? dataItem?.acf?.summary_gl : dataItem?.acf?.summary_da,
        image: dataItem?.acf?.image?.sizes?.medium,
        categories: dataItem?.acf?.category ? [dataItem.acf.category] : [],
        subjects: dataItem?.acf?.subject ? [dataItem.acf.subject] : [],
        formats: dataItem?.acf?.formats ? dataItem.acf.formats : [],
    }

    return cardItem;
}


export const wpLandingPagetoLandingPageItem = (landingpage: WpLandingpage | undefined): LandingPageItem | undefined => {
    if(!landingpage){
        return
    }
    const isLangGL = i18n?.language === "gl";
    const PageItem: LandingPageItem =
    {
        title: isLangGL ? landingpage?.acf?.title_gl : landingpage?.title?.rendered,
        slug: landingpage.slug,
        summary: isLangGL ? landingpage?.acf?.summary_gl : landingpage?.acf?.summary_dk,
        featured_first: landingpage?.acf?.featured_first ? landingpage.acf.featured_first : [],
        featured_second: landingpage?.acf?.featured_second ? landingpage.acf.featured_second: [],
        featured_third: landingpage?.acf?.featured_third ? landingpage.acf.featured_third : [],
        featured_databundles: landingpage?.acf?.featured_databundles ? landingpage.acf.featured_databundles : [],
        hero_image: landingpage?.acf?.hero_image
    }

    return PageItem;
} 


export const wpLinkcardtoLinkcardItem = (linkcard: WpLinkcard ): LinkCardItem  => {
    
    const isLangGL = i18n?.language === "gl";
    const PageItem: LinkCardItem =
    {
        id: linkcard.id,
        slug: linkcard.slug,
        summary: isLangGL ? linkcard?.acf?.summary_gl : linkcard?.acf?.summary_dk,
        cardType: linkcard?.acf?.card_type ? linkcard.acf.card_type : "",
        url_link: linkcard?.acf?.url_link ? linkcard.acf.url_link : "",
        image_card_thumbnail: linkcard?.acf?.image_card_thumbnail ? linkcard.acf.image_card_thumbnail : "",
        archive_select_subject: linkcard?.acf?.archive_select_subject ? linkcard.acf.archive_select_subject : "",
        archive_featured_articles: linkcard?.acf?.archive_featured_articles ? linkcard.acf.archive_featured_articles : [],
        subject: linkcard?.acf?.subject?.name,
        subjectSlug: "",
        related:  linkcard?.acf?.archive_featured_articles ? linkcard.acf.archive_featured_articles : [],
        thumbnail: "",
        portals: [],
        date: linkcard.date,
        title: "",
        content: "",
    }

    return PageItem;
} 