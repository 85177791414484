import { useState, useEffect } from 'react'
import { fetchAsJson } from './Fetch.Service'
import { WpImage, WpPost, WpRender, WpTaxonomyTerm } from './WpApiCommonDataModels';

interface WpLandingpageCustomFields {
    featured_databundles: number[];
    title_gl: string;
    summary_dk: string;
    summary_gl: string;
    featured_first: number[];
    featured_second: number[];
    featured_third: number[];
    hero_image: string;

}

export interface WpLandingpage extends WpPost {
    title: WpRender;
    slug: string;
    acf: WpLandingpageCustomFields;
}

interface FetchLandingpageItems {
    landingpage: WpLandingpage[];
    isPending: boolean;
    error?: any;
}

export const url = "/wp-json/wp/v2"
const landingpageSlug = "landingpages"

export const useFetchLandingpageItems = (): FetchLandingpageItems => {
    const [landingpage, setLandingPage] = useState<WpLandingpage[]>([]);
    const [isPending, setIsPending] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        const abort = new AbortController();
        const dataBundleUrl = `${url}/${landingpageSlug}?per_page=100`
        fetchAsJson(dataBundleUrl, abort).then(res => {
            const page = res.data ?? []
            setLandingPage(page)
            setIsPending(res.isPending)
            setError(res.error)
        })
        return () => abort.abort();
    }, []);

    return (
        { landingpage, isPending, error }
    )
}

