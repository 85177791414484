import { useTranslation } from "react-i18next";
import { getCategories } from "../api/Taxonomy";

interface CategoryFilterInput {
    changeCategories: Function;
}

const CategoryFilter = ({changeCategories}: CategoryFilterInput) => {
    const { t } = useTranslation('translation', { keyPrefix: 'categoryfilter' });

    const allCategories = getCategories();

    const selectCategories = (value: number, selected: boolean) => {
        if(selected){
            changeCategories((prev :number[]) => [...prev, value])
        } else {
            changeCategories((prev :number[]) => prev.filter(cat => cat !== value))
        }
    }

    return (
        <div className='filter'>
            <p>{t("label")}</p>
            {
                allCategories &&
                allCategories.map((tax, index) => {
                    return (
                        <div key={index}>
                            <input type="checkbox" 
                                id={'cat' + index} 
                                onChange={(e) => { selectCategories(parseInt(e.target.value), e.target.checked)}}
                                name="category" 
                                value={tax.id} />
                            <label htmlFor={'cat' + index}>{t(tax.slug)}</label>
                        </div>
                    );
                })
            }
        </div>
    )
}

export default CategoryFilter