import { WpTaxonomy } from "./WpApiCommonDataModels";

export const getCategories = (): WpTaxonomy[] => {
    const allCategories: WpTaxonomy[] = [
        {
            id: 23,
            name: "Beredskabsdata",
            slug: "beredskabsdata"
        },
        {
            id: 18,
            name: "Grunddata",
            slug: "grunddata"
        },
        {
            id: 19,
            name: "Kortdata",
            slug: "kortdata"
        },
        {
            id: 22,
            name: "Maritime data",
            slug: "maritimedata"
        },
        {
            id: 24,
            name: "Miljødata",
            slug: "miljodata"
        },
        {
            id: 21,
            name: "Plandata",
            slug: "plandata"
        },
        {
            id: 20,
            name: "Referencedata",
            slug: "referencedata"
        }
    ]; // TODO: get from `/wp-json/wp/v2/category_db`
    return allCategories;
}

export const getCategory = (id: number): WpTaxonomy => {
    const categories = getCategories();
    return categories.find((category) => category?.id === id) ?? {id: id, name:"unknown", slug:""}
}

export const getSubjects = (): WpTaxonomy[] => {
    const allSubjects: WpTaxonomy[] = [
        {
            id: 7,
            name: "Erhverv & vækst",
            slug: "business"
        },
        {
            id: 8,
            name: "Infrastruktur & bolig",
            slug: "infrastructure"
        },
        {
            id: 9,
            name: "Forsyning",
            slug: "supply"
        },
        {
            id: 10,
            name: "Miljø & natur",
            slug: "nature"
        },
        {
            id: 11,
            name: "Klima & beredskab",
            slug: "climate"
        },
        {
            id: 12,
            name: "Sundhed & forebyggelse",
            slug: "health"
        },
        {
            id: 13,
            name: "Uddannelse & Kulturarv",
            slug: "education"
        },
        {
            id: 14,
            name: "Digitalisering & innovation",
            slug: "innovation"
        },
        {
            id: 15,
            name: "Lands-planlægning",
            slug: "planning"
        },
        {
            id: 16,
            name: "Bæredygtig udvikling",
            slug: "sustainability"
        },
    ]; // TODO: get from `/wp-json/wp/v2/subject`
    return allSubjects;
}

export const getSubject = (id: number): WpTaxonomy => {
    const subjects = getSubjects();
    return subjects.find((subject) => subject?.id === id) ?? {id: id, name:"unknown", slug:""}
}

export const getFormats = (): WpTaxonomy[] => {
    const allFormats: WpTaxonomy[] = [
        {
            id: 27,
            name: "Dokument",
            slug: "document"
        },
        {
            id: 25,
            name: "ESRI JSON",
            slug: "json"
        },
        {
            id: 26,
            name: "GeoJSON",
            slug: "geojson"
        },
        {
            id: 30,
            name: "GeoPackage",
            slug: "geopackage"
        },
        {
            id: 28,
            name: "GeoTIF",
            slug: "geotif"
        },
        {
            id: 29,
            name: "Shapefile",
            slug: "shp"
        }
    ]; // TODO: get from `/wp-json/wp/v2/file_format`
    return allFormats;
}

export const getFormat = (id: number): WpTaxonomy => {
    const formats = getFormats();
    return formats.find((format) => format?.id === id) ?? {id: id, name:"unknown", slug:""}
}
