import DataSearch from './DataSearch'
import Hero from '../layout/hero/Hero';
import { WpDataBundle } from '../api/WpDataBundle';
import { wpDataBundleToDataCardItem } from '../api/ItemMapper';
import { DataCardItem } from './DataCard';
import { useTranslation } from 'react-i18next';
import './DataCatalog.css';
import OfflineIcon from './OfflineIcon';
import Spinners from '../Spinners';

interface DataCatalogInput{
    items: WpDataBundle[];
}

const DataCatalogPage = ({ items }: DataCatalogInput) => {
    
    const { t } = useTranslation('translation', { keyPrefix: 'datacatalog' });

    let cards: DataCardItem[] = []
    if(items && items.length > 0){
        items.forEach(item => {
            cards.push(wpDataBundleToDataCardItem(item));
        });
    }

    const imageURL = '/assets/hero/img-datakatlog.jpg'

    return (
        <>
            {!cards.length && <div className='loading'><Spinners /></div>}
            <div className="flex flex-col items-center mx-auto w-full justify-between">
                <div className="hero-container hide-in-mobile">
                    <Hero imageURL={imageURL}/>
                    <div className="under-construction-overlay"></div>
                    <div className="under-construction-header">
                        <h1>{t("hero_overlay_h")}</h1>
                        <p>{t("hero_overlay_p1")}</p>
                        <p>{t("hero_overlay_p2")}</p>
                    </div>
                </div>
                <div className="header-container offline">
                    <div className="svg-container">
                        <img
                            src="/assets/icons/icon_datakatalog.svg"
                            alt=""
                        />
                        <OfflineIcon></OfflineIcon>
                    </div>
                    <h1>{t("datacatalog")}</h1>
                    <p>{t("herolabel__OFFLINE")}</p>
                </div>
                {cards.length > 0 && <DataSearch cards={cards} />}
            </div>
        </>
    )
}

export default DataCatalogPage


