import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom"
import { ArticleItem } from "../article/Article";

interface ArticleCardImageInput {
    info: ArticleItem;
    colspan: number;
}

const ArticleCardImage = (props: ArticleCardImageInput) =>{
    const {info, colspan} = props
    const { t } = useTranslation('translation', { keyPrefix: 'linkcard' });
    const thumbnail = info?.image_card_thumbnail

    const isLandscape = colspan > 3;
    const orientationClass = isLandscape ? "landscape-orient" : "portrait-orient";
    const cardheight =  isLandscape ?  242 : 329;
    const title = t(info.slug);
    return (
        <NavLink to={`${info.url_link}`} className={"max-w-[330px] sm:max-w-[500px] md:w-full"}  style={{ gridColumn : `span ${colspan} / span ${colspan}`}}>
            <article 
                className={`bg-[#141414] rounded-tl-lg flex flex-col-reverse bg-cover link-card ${orientationClass}`}
                style={colspan > 4 ? {flexDirection: "row"}: {"backgroundImage" : `url(${thumbnail})`, height: `${cardheight}px`, gridColumn : `span ${colspan} / span ${colspan}` }} 
            >
                <div className="w-2/3" style={colspan > 4 ? {"backgroundImage" : `url(${thumbnail})`} : {display:"hidden"}} >
                </div>
                <div className="p-4 pb-7 h-full w-full bg-[#141414] bg-opacity-80" style={colspan > 4 ? {"height" : "100%"} : {"height" : "50%"}}>
                    <p
                        title={title}
                        className="text-start break-word text-[9px] font-medium mb-2 capitalize truncate">{title}
                    </p>
                    <p 
                        title={info.summary}
                        className="break-word text-start w-100 text-clip overflow-hidden h-full card-item-snippet p-0"
                    >
                        {info.summary}
                    </p>
                </div>
            </article>
        </NavLink>


    )
}

export default ArticleCardImage;